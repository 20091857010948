import { Injectable, signal } from '@angular/core';
import { environment } from '@core/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggerService } from '@services/logger/logger.service';
import { SignalRHubService } from '@signalr/signalr.hub.service';
import { QueueService } from '@services/queue/queue.service';
import { AgentService } from '@services/agent/agent.service';
import { IQueueInformation } from '@models/queue/queue';
import { IAgentState } from '@models/agent/agent';

@Injectable({
    providedIn: 'root',
})
export class LiveHubService extends SignalRHubService {
    constructor(
        private queueService: QueueService,
        private agentService: AgentService,
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Live',
            `${environment.liveUrl}/hub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveQueueData',
            (queueInformation: IQueueInformation) => {
                this.messageReceived();

                this.logger.debug('Live Service (ReceiveQueueData) ->');
                this.logger.table(queueInformation);

                this.queueService.setInformation(queueInformation);
            },
        );

        this.hubConnection.on(
            'ReceiveTenantAgentStates',
            (tenantAgentStates: IAgentState[]) => {
                this.messageReceived();

                this.logger.debug('Live Service (ReceiveTenantAgentStates) ->');
                this.logger.table(tenantAgentStates);

                this.agentService.setState(tenantAgentStates);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveQueueData');
        this.hubConnection.off('ReceiveTenantAgentStates');
    }
}
