import { Injectable, signal } from '@angular/core';
import { Agent, IAgentReport, IAgentState } from '@models/agent/agent';
import { LoggerService } from '@services/logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class AgentService {
    agents = signal<Agent[]>([]);

    constructor(private loggerService: LoggerService) {}

    set(agentIds: string[]) {
        const agents = agentIds.map(id => new Agent(id));
        this.agents.set(agents);
    }

    setReport(agentReport: IAgentReport) {
        if (agentReport.name === '') return;

        const agentUpdate = this.agents
            .asReadonly()()
            .map(a =>
                a.id === agentReport.id
                    ? new Agent(a.id, agentReport, a.state)
                    : a,
            );

        this.agents.set(agentUpdate);
    }

    setState(agentStates: IAgentState[]) {
        agentStates
            .filter(as => as.agentName !== '')
            .forEach(as => {
                const agentUpdate = this.agents
                    .asReadonly()()
                    .map(a =>
                        a.id === as.agentId ? new Agent(a.id, a.report, as) : a,
                    );

                this.agents.set(agentUpdate);
            });
    }
}
