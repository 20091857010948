import { AgentState, AgentStates, MediaType, MediaTypes } from './agent-state';
export type KeyValuePair<T> = { key: string; value: T };

const reduceToObject = <TValue, TRes>(
    arr: KeyValuePair<TValue>[],
    selector: (value: TValue) => unknown = v => v,
): Record<string, TRes> =>
    arr.reduce(
        (coll, { key, value }) => ({ ...coll, [key]: selector(value) }),
        {},
    );

export const recordToArray = (
    record: Record<string, { value: number; percentage: number }>,
): {
    name: string;
    value: number;
    percentage: number;
}[] => {
    return Object.keys(record).map(key => ({
        name: key,
        value: record[key].value,
        percentage: record[key].percentage,
    }));
};

export interface IAgentState {
    agentId: string;
    agentName: string;
    state: AgentState;
    stateSince: Date;
    breakName: string;
}

export interface IAgentReport {
    id: string;
    name: string;
    tenantId: string;
    workItemKpisPerMediaType: KeyValuePair<IWorkItemKpis>[];
    workItemKpisPerDispositionCode: KeyValuePair<IHandledCountKpi>[];
    secondsSpentPerState: KeyValuePair<number>[];
    secondsSpentPerBreakName: KeyValuePair<number>[];
    loggedInAt?: Date;
    loggedOutAt?: Date;
}

interface IWorkItemKpis {
    handledCount: number;
    missedCount: number;
    averageHandlingTimeInSeconds: number;
}

interface IHandledCountKpi {
    handledCount: number;
}

export interface IAgent {
    id: string;
    report?: IAgentReport;
    state?: IAgentState;
}

export interface IAgentPerformance {
    timeSpentInAgentStates: Record<
        AgentState,
        { value: number; percentage: number }
    >;
    timeSpentInBreakNames: Record<
        string,
        { value: number; percentage: number }
    >;
    workItemDispositionCodes: Record<
        string,
        { value: number; percentage: number }
    >;
    workItemsPerMediaType: Record<
        MediaType,
        {
            handledCount: number;
            missedCount: number;
            averageHandlingTimeInSeconds: number;
            successRate: number;
        }
    >;
}

export class Agent implements IAgent {
    id: string;
    report?: IAgentReport;
    state?: IAgentState;

    constructor(id: string, report?: IAgentReport, state?: IAgentState) {
        this.id = id;
        this.report = report;
        this.state = state;
    }

    get name(): string | undefined {
        return this.report?.name;
    }

    get hasActivity(): boolean {
        return true;
    }

    get status(): { state: AgentState; since: Date; breakName: string } {
        return this.state
            ? {
                  state: this.state.state,
                  since: this.state.stateSince,
                  breakName: this.state.breakName ?? 'Default break',
              }
            : {
                  state: 'LoggedOut',
                  since: new Date(),
                  breakName: 'Default break',
              };
    }

    get performance(): IAgentPerformance {
        return {
            timeSpentInAgentStates: this.calculateWithPercentage(
                AgentStates.filter(
                    as => as !== 'LoggedOut',
                ) as readonly string[],
                this.report?.secondsSpentPerState ?? [],
                value => value,
            ),

            timeSpentInBreakNames: this.calculateWithPercentage(
                this.report?.secondsSpentPerBreakName.map(kvp => kvp.key) ?? [],
                this.report?.secondsSpentPerBreakName ?? [],
                value => value,
            ),
            workItemDispositionCodes: this.calculateWithPercentage(
                this.report?.workItemKpisPerDispositionCode.map(
                    kvp => kvp.key,
                ) ?? [],
                this.report?.workItemKpisPerDispositionCode ?? [],
                item => item.handledCount,
            ),
            workItemsPerMediaType: this.convertToMediaTypeRecord(
                this.report?.workItemKpisPerMediaType ?? [],
            ),
        };
    }

    private calculateWithPercentage<T>(
        keys: readonly string[],
        data: KeyValuePair<T>[],
        getValue: (item: T) => number,
    ): Record<string, { value: number; percentage: number }> {
        const result = reduceToObject<T, T>(data);

        const stateObj = keys.reduce(
            (acc, key) => {
                acc[key] = { value: 0, percentage: 0 };
                return acc;
            },
            {} as Record<string, { value: number; percentage: number }>,
        );

        if (data.length === 0) {
            return stateObj;
        }

        let totalValue = keys.reduce((total, key) => {
            const value = getValue(result[key]) ?? 0;
            stateObj[key].value = value;
            return total + value;
        }, 0);

        if (totalValue > 0) {
            let totalRoundedPercentage = 0;
            const unroundedPercentages: Record<string, number> = {};

            keys.forEach(key => {
                const unroundedPercentage =
                    (stateObj[key].value / totalValue) * 100;
                unroundedPercentages[key] = unroundedPercentage;
                const roundedPercentage = Math.round(unroundedPercentage);
                stateObj[key].percentage = roundedPercentage;
                totalRoundedPercentage += roundedPercentage;
            });

            const difference = 100 - totalRoundedPercentage;

            if (difference !== 0) {
                const adjustmentKey = keys.reduce((acc, key) => {
                    const error =
                        unroundedPercentages[key] - stateObj[key].percentage;
                    return Math.abs(error) >
                        Math.abs(
                            unroundedPercentages[acc] -
                                stateObj[acc].percentage,
                        )
                        ? key
                        : acc;
                }, keys[0]);

                stateObj[adjustmentKey].percentage += difference;
            }
        }

        return stateObj;
    }

    private convertToMediaTypeRecord(
        data: KeyValuePair<IWorkItemKpis>[],
    ): Record<
        MediaType,
        {
            handledCount: number;
            missedCount: number;
            averageHandlingTimeInSeconds: number;
            successRate: number;
        }
    > {
        const acc = MediaTypes.reduce(
            (result, mediaType) => {
                result[mediaType] = {
                    handledCount: 0,
                    missedCount: 0,
                    averageHandlingTimeInSeconds: 0,
                    successRate: 0,
                };
                return result;
            },
            {} as Record<
                MediaType,
                {
                    handledCount: number;
                    missedCount: number;
                    averageHandlingTimeInSeconds: number;
                    successRate: number;
                }
            >,
        );

        data.forEach(({ key, value }) => {
            if (MediaTypes.includes(key as MediaType)) {
                const totalInteractions =
                    value.handledCount + value.missedCount;
                const successRate =
                    totalInteractions > 0
                        ? (value.handledCount / totalInteractions) * 100
                        : 0;

                acc[key as MediaType] = {
                    handledCount: value.handledCount,
                    missedCount: value.missedCount,
                    averageHandlingTimeInSeconds:
                        value.averageHandlingTimeInSeconds,
                    successRate: Math.round(successRate),
                };
            }
        });

        return acc;
    }
}
